import { environment } from "../../environments/environment";
import * as CryptoJS from 'crypto-js';

export class EncryptDecrypt {

     static encryptWithKey(myString: any){
          var encrypted = CryptoJS.AES.encrypt(myString, CryptoJS.enc.Utf8.parse(environment.key), { mode: CryptoJS.mode.ECB });
          return encrypted.toString();
     }

     static decryptWithKey(decString: any){
          const encryptedData = CryptoJS.enc.Base64.parse(decString);
          const key = CryptoJS.enc.Utf8.parse(environment.key);
          const decryptor = CryptoJS.algo.AES.createDecryptor(key, { mode: CryptoJS.mode.ECB });
          const decryptedData = decryptor.finalize(encryptedData);
          const decryptedString = CryptoJS.enc.Utf8.stringify(decryptedData);
          const unpaddedData = decryptedString.replace(/\0+$/, '');
          return unpaddedData;
     }

}